<template>
	<div>
		<div class="bg-white p-3 rounded">
			<div class="input-group flex-nowrap mb-3" v-if="prendas.length > 0">
				<div class="input-group-prepend">
					<span class="input-group-text" id="addon-wrapping">
						<img v-lazy="imgSearch" width="18" height="18">
					</span>
				</div>
				<input id="searchprendasFavorito" type="text" class="form-control bg-light border-left-0 form-control-placeholder-bold" placeholder="Buscar" aria-label="Buscar" aria-describedby="addon-wrapping" v-model="searchprendasFavorito" name="searchprendasFavorito">
			</div>
			<div class="form-row" v-if="prendasFiltered.length>0">
				<div class="col-md-3 mb-2" v-for="(prenda, index) in prendasFiltered" :key="index">
					<router-link class="card rounded cursor-pointer" :to="{name:'opciones-inversion.show', params:{id:prenda.idprenda}}">
						<img v-lazy="$filters.storageMin(prenda.ruta)" class="card-img-top" :alt="prenda.nombre" style="max-height: 200px;" v-if="prenda.ruta">
						<div :class="{'px-2 font-weight-bold text-white':true, 'bg-inversion':prenda.estado==1, 'bg-gray':prenda.estado!=1}">
							<div v-if="prenda.estado==1">
								Invierte
							</div>
							<div v-if="prenda.estado==5">
								Préstamo otorgado
							</div>
						</div>
						<div class="rounded-bottom bg-primary text-white py-2 px-3">
							<div class="text-truncate ">
								<span class="font-weight-bold">{{prenda.nombre}}</span>
							</div>
							> monto: {{$filters.currency(prenda.precio)}}
						</div>
					</router-link>
				</div>
			</div>
			<div v-else>
				<div class="text-center py-5" v-if="prendas.length <= 0">
					<div class="bg-light p-3 m-auto d-flex align-items-center justify-content-center rounded-circle" style="height: 150px; width: 150px;">
						<img v-lazy="imgSearch" class="img-fluid">
					</div>
					<h5>
					Aún no tienes prendas favoritas.
					</h5>
				</div>
				<div class="text-center py-5" v-else>
					<div class="bg-light p-3 m-auto d-flex align-items-center justify-content-center rounded-circle" style="height: 150px; width: 150px;">
						<img v-lazy="imgSearch" class="img-fluid">
					</div>
					<h5>
					No se encontraron resultados en la búsqueda.
					</h5>
				</div>
			</div>
		</div>
	</div>
</template>
<script type="text/javascript">
	import imgSearch from '@/assets/image/search_dark.webp'
	export default {
		data(){
			return {
				imgSearch,
				loadingData: false,
				prendas: [],
				searchprendasFavorito: ''
			}
		},
		mounted(){
			this.getPublicacionesFav()
		},
		methods: {
			getPublicacionesFav(){
				if (!this.user.idusuario) {return}
				this.loadingData = true
				this.$store.dispatch('getPublicacionesFav',{
					idusuario: this.user.idusuario
				}).then(response => {
					this.prendas = response.data || []
					return response
				}).catch(error =>{
					return error
				}).then(() => {
					this.loadingData = false
				})
			}
		},
		computed: {
			user(){
				return this.$store.getters.currentUser || {}
			},
			prendasFiltered(){
				if (this.searchprendasFavorito != '') {
					if (this.prendas.constructor === Array) {
						return this.prendas.filter(x => 
							(x.nombre || '').toString().includes(this.searchprendasFavorito) || 
							(x.idprenda || '').toString().includes(this.searchprendasFavorito) || 
							(x.ruta || '').toString().includes(this.searchprendasFavorito) || 
							(x.estado || '').toString().includes(this.searchprendasFavorito) || 
							(x.precio || '').toString().includes(this.searchprendasFavorito)
						)
					}
				}
				return this.prendas || []
			}
		},
		watch:{
			'user':{
				handler(val){
					this.getPublicacionesFav()
					return val
				},
				deep: true
			}
		}
	}
</script>