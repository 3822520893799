<template>
	<div>
		<div class="card text-primary mb-3">
			<div class="card-body d-flex justify-content-between">
				<h5 class="text-primary font-weight-bold text-left mx-lg-0 mx-3 d-flex align-items-center mb-0">
				<i class="material-icons">help_outline</i> Sugerencias
				</h5>
				<router-link :to="{name:'perfil.sugerencias.create'}" class="btn btn-primary btn-sm border">
					Agregar
				</router-link>
			</div>
		</div>
		<div>
			<div class="card">
				<div class="card-body">
					<div class="table-responsive" v-if="sugerencias.length>0">
						<table class="table">
							<thead>
								<tr>
									<th class="text-center" style="width: 120px">Fecha</th>
									<th class="text-center">Título</th>
									<th class="text-center">Descripción</th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="(sugerencia, index) in sugerencias" :key="index">
									<th align="center">{{$filters.date(sugerencia.fechaCreacion)}}</th>
									<td>{{sugerencia.titulo}}</td>
									<td>{{sugerencia.descripcion}}</td>
								</tr>
							</tbody>
						</table>
					</div>
					<div class="card" v-if="sugerencias.length<=0">
						<div class="card-body">
							<div class="text-center py-5">
								<div class="bg-light p-3 m-auto d-flex align-items-center justify-content-center rounded-circle" style="height: 150px; width: 150px;">
									<img src="@/assets/image/search_dark.webp" class="img-fluid">
								</div>
								<h5>
								Aún no tienes sugerencias.
								</h5>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<router-view></router-view>
	</div>
</template>
<script type="text/javascript">
	export default {
		created(){
			this.getSugerencias()
		},
		methods: {
			getSugerencias(){
				if (!this.user.idusuario) {return}
				this.loadingSugerencias = true
				this.$store.dispatch('getSugerencias',{
					idusuario: this.user.idusuario
				}).then(response => {
					return response
				}).catch(error =>{
					return error
				}).then(() => {
					this.loadingSugerencias = false
				})
			}
		},
		computed: {
			user(){
				return this.$store.getters.currentUser || {}
			},
			sugerencias(){
				return this.$store.getters.sugerencias || []
			},
		},
		watch:{
			'user': {
				handler(val){
					this.getSugerencias()
					return val
				},
				deep: true
			}
		}
	}
</script>