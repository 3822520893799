<template>
	<div>
		<div class="bg-white p-3 rounded">
			<div class="input-group flex-nowrap mb-3" v-if="usuarios.length > 0">
				<div class="input-group-prepend">
					<span class="input-group-text" id="addon-wrapping">
						<img v-lazy="imgSearch" width="18" height="18">
					</span>
				</div>
				<input id="searchusuariosFavorito" type="text" class="form-control bg-light border-left-0 form-control-placeholder-bold" placeholder="Buscar" aria-label="Buscar" aria-describedby="addon-wrapping" v-model="searchusuariosFavorito" name="searchusuariosFavorito">
			</div>

			<div class="table-responsive" v-if="usuariosFiltered.length>0">
				<table class="table table-sm">
					<thead>
						<tr>
							<th class="text-center" style="width: 35px;">No.</th>
							<th v-on:click="changeSorting('nombre')" class="cursor-pointer text-center">
								Usuario
								<span v-if="sort.column == 'nombre'">
									<span class="material-icons">{{sort.icon}}</span>
								</span>
							</th>
							<th v-on:click="changeSorting('perfil')" class="cursor-pointer text-center">
								Perfil
								<span v-if="sort.column == 'perfil'">
									<span class="material-icons">{{sort.icon}}</span>
								</span>
							</th>
							<th class="text-center" style="width: 50px">Acciones</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="(usuario, index) in usuariosFiltered" :key="index">
							<th class="text-center">{{index+1}}</th>
							<td class="text-center">{{usuario.alias}}</td>
							<td class="text-center">{{usuario.perfil}}</td>
							<td class="text-center">
								<router-link :to="{name:'usuarios.show', params:{id:usuario.idusuario}}" class="btn btn-primary btn-sm p-1" title="Ver usuario" style="border-radius: 5px;">
									<img v-lazy="imgSearchWhite" width="18" height="18">
								</router-link>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
			<div v-else>
				<div class="text-center py-5" v-if="usuarios.length <= 0">
					<div class="bg-light p-3 m-auto d-flex align-items-center justify-content-center rounded-circle" style="height: 150px; width: 150px;">
						<img v-lazy="imgSearch" class="img-fluid">
					</div>
					<h5>
					Aún no tienes usuarios favoritos.
					</h5>
				</div>
				<div class="text-center py-5" v-else>
					<div class="bg-light p-3 m-auto d-flex align-items-center justify-content-center rounded-circle" style="height: 150px; width: 150px;">
						<img v-lazy="imgSearch" class="img-fluid">
					</div>
					<h5>
					No se encontraron resultados en la búsqueda.
					</h5>
				</div>
			</div>
		</div>
	</div>
</template>
<script type="text/javascript">
	import imgSearch from '@/assets/image/search_dark.webp'
	import imgSearchWhite from '@/assets/image/search.webp'
	export default {
		data(){
			return {
				imgSearch,
				imgSearchWhite,
				loadingData: false,
				usuarios: [],
				searchusuariosFavorito: '',
				sort: {
					column: '',
					descending: false,
					icon: ''
				}
			}
		},
		mounted(){
			this.getFavoriteUsers()
		},
		methods: {
			getFavoriteUsers(){
				if (!this.user.idusuario) {return}
				this.loadingData = true
				this.$store.dispatch('getFavoriteUsers',{
					idusuario: this.user.idusuario
				}).then(response => {
					this.usuarios = response.data || []
					return response
				}).catch(error =>{
					return error
				}).then(() => {
					this.loadingData = false
				})
			},
			changeSorting(column){
				return column
			}
		},
		computed: {
			user(){
				return this.$store.getters.currentUser || {}
			},
			usuariosFiltered(){
				if (this.searchusuariosFavorito != '') {
					if (this.usuarios.constructor === Array) {
						return this.usuarios.filter(x => 
							(x.perfil || '').toString().includes(this.searchusuariosFavorito) || 
							(x.alias || '').toString().includes(this.searchusuariosFavorito)
						)
					}
				}
				return this.usuarios || []
			}
		},
		watch:{
			'user':{
				handler(val){
					this.getFavoriteUsers()
					return val
				},
				deep: true
			}
		}
	}
</script>