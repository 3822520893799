<template>
	<div>
		<div class="bg-white py-5 px-lg-5 rounded text-right">
			<div class="form-row justify-content-center">
				<div class="col-lg-6 mb-3">
					<h5 class="text-primary font-weight-bold text-left mx-lg-0 mx-3">Propuestas realizadas por mes</h5>
					<PropuestasRealizadas :datos="propuestasRealizadas" v-if="loadingPropuestasRealizadas" width="100%" height="80"/>
				</div>
				<div class="col-lg-6 mb-3">
					<h5 class="text-primary font-weight-bold text-left mx-lg-0 mx-3">Total de propuestas realizadas</h5>
					<TotalPropuestasRealizadas :datos="totalPropuestasRealizadas" v-if="loadingTotalPropuestasRealizadas" width="100%" height="80"/>
				</div>
				<div class="col-lg-9 mb-3">
					<h5 class="text-primary font-weight-bold text-center mx-lg-0 mx-3">Inversión por mes</h5>
					<Inversion :datos="inversion" v-if="loadingInversion" width="100%" height="30"/>
				</div>
				<div class="col-lg-5 mb-3 mx-lg-0 mx-3">
					<div class="card rounded overflow-hidden">
						<div class="card-header bg-inversion text-primary">
							<h5 class="font-weight-bold mb-0 text-center">Inversión total (Q)</h5>
						</div>
						<div class="card-body text-center font-weight-bold text-primary bg-light">
							<h5 class="mb-0 font-weight-bold">{{$filters.currency(total.total)}}</h5>
						</div>
					</div>
				</div>
			</div>
			<div class="row justify-content-center">
				<div class="col-lg-6 mb-3">
					<h5 class="text-primary font-weight-bold text-left mx-lg-0 mx-3">Publicaciones por mes</h5>
					<PublicacionesRealizadas :datos="publicacionesRealizadas" v-if="loadingPublicacionesRealizadas" width="100%" height="80"/>
				</div>
				<div class="col-lg-6 mb-3">
					<h5 class="text-primary font-weight-bold text-left mx-lg-0 mx-3">Total Publicaciones</h5>
					<TotalPublicacionesRealizadas :datos="totalPublicacionesRealizadas" v-if="loadingTotalPublicacionesRealizadas" width="100%" height="30"/>
				</div>
				<div class="col-lg-9 mb-3">
					<h5 class="text-primary font-weight-bold text-center mx-lg-0 mx-3">Inversión por mes</h5>
					<Inversion :datos="receivedMoney" v-if="loadingReceivedMoney" />
				</div>
				<div class="col-lg-5 mb-3 mx-lg-0 mx-3">
					<div class="card rounded overflow-hidden">
						<div class="card-header bg-credito text-primary">
							<h5 class="font-weight-bold mb-0 text-center">Recaudación total (Q)</h5>
						</div>
						<div class="card-body text-center font-weight-bold text-primary bg-light">
							<h5 class="mb-0 font-weight-bold">{{$filters.currency(total.total)}}</h5>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script type="text/javascript">
	import Inversion from '@/components/estadisticas/Inversion.vue'
	import PropuestasRealizadas from '@/components/estadisticas/PropuestasRealizadas.vue'
	import TotalPropuestasRealizadas from '@/components/estadisticas/TotalPropuestasRealizadas.vue'
	import PublicacionesRealizadas from '@/components/estadisticas/PublicacionesRealizadas.vue'
	import TotalPublicacionesRealizadas from '@/components/estadisticas/TotalPublicacionesRealizadas.vue'
	export default {
		components: {
			Inversion,
			PropuestasRealizadas,
			TotalPropuestasRealizadas,
			PublicacionesRealizadas,
			TotalPublicacionesRealizadas
		},
		data(){
			return {
				loadingTotalMoneyUser: false,
				loadingReceivedMoney: false,
				loadingInversion: false,
				loadingPropuestasRealizadas: false,
				loadingTotalPropuestasRealizadas: false,
				loadingPublicacionesRealizadas: false,
				loadingTotalPublicacionesRealizadas: false,
				totalMoney: [],
				receivedMoney: [],
				inversion: [],
				propuestasRealizadas: [],
				publicacionesRealizadas: [],
				totalPublicacionesRealizadas: [],
				chartdata: {
					labels: ['Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange'],
			        datasets: [{
			            data: [12, 19, 3, 5, 2, 3],
			            label: 'Dinero',
			            borderColor: "#3e95cd",
			            fill: true
			        }]
				},
				options: {
					scales: {
			            y: {
			                beginAtZero: true
			            }
			        }
				}
			}
		},
		mounted(){
			this.getData()
		},
		methods:{
			getData(){
				if (!this.user.idusuario) {return}
				this.getPropByInvMonth()
				this.getMyProposal()
				this.getInvertedMoney()

				this.getCountPubUserMonth()
				this.getCountPubUser()
				this.getReceivedMoney()
				this.getTotalMoneyUser()
			},
			getTotalMoneyUser(){
				this.$store.dispatch('getTotalMoneyUser',{
					idusuario: this.user.idusuario
				}).then(response => {
					this.totalMoney = response.data || []
					this.loadingTotalMoneyUser = true
					return response
				}).catch(error =>{
					return error
				}).then(() => {
					//this.loadingTotalMoneyUser = false
				})
			},
			getReceivedMoney(){
				this.$store.dispatch('getReceivedMoney',{
					idusuario: this.user.idusuario
				}).then(response => {
					this.receivedMoney = response.data || []
					this.loadingReceivedMoney = true
					return response
				}).catch(error =>{
					return error
				}).then(() => {
					//this.loadingReceivedMoney = false
				})
			},
			getInvertedMoney(){
				this.$store.dispatch('getInvertedMoney',{
					idusuario: this.user.idusuario
				}).then(response => {
					this.inversion = response.data || []
					this.loadingInversion = true
					return response
				}).catch(error =>{
					return error
				}).then(() => {
					//this.loadingInversion = false
				})
			},
			getPropByInvMonth(){
				this.$store.dispatch('getPropByInvMonth',{
					idusuario: this.user.idusuario
				}).then(response => {
					this.propuestasRealizadas = response.data || []
					this.loadingPropuestasRealizadas = true
					return response
				}).catch(error =>{
					return error
				}).then(() => {
					//this.loadingInversion = false
				})
			},
			getMyProposal(){
				if(this.totalPropuestasRealizadas.length>0) {
					this.loadingTotalPropuestasRealizadas = true
					return
				}
				this.$store.dispatch('getMyProposal',{
					idusuario: this.user.idusuario
				}).then(response => {
					this.loadingTotalPropuestasRealizadas = true
					return response
				}).catch(error =>{
					return error
				}).then(() => {
					//this.loadingInversion = false
				})
			},
			getCountPubUserMonth(){
				this.$store.dispatch('getCountPubUserMonth',{
					idusuario: this.user.idusuario
				}).then(response => {
					this.publicacionesRealizadas = response.data || []
					this.loadingPublicacionesRealizadas = true
					return response
				}).catch(error =>{
					return error
				}).then(() => {
					//this.loadingInversion = false
				})
			},
			getCountPubUser(){
				this.$store.dispatch('getCountPubUser',{
					idusuario: this.user.idusuario
				}).then(response => {
					this.totalPublicacionesRealizadas = response.data || []
					this.loadingTotalPublicacionesRealizadas = true
					return response
				}).catch(error =>{
					return error
				}).then(() => {
					//this.loadingInversion = false
				})
			},
		},
		computed:{
			total(){
				return this.totalMoney[1] || {}
			},
			user(){
				return this.$store.getters.currentUser || {}
			},
			totalPropuestasRealizadas(){
				return this.$store.getters.misPropuestas || []
			},
		},
		watch:{
			'user':{
				handler(val){
					this.getData()
					return val
				},
				deep: true
			}
		}
	}
</script>