<template>
	<div class="modal fade" id="incidente" data-backdrop="static" role="dialog" aria-labelledby="incidenteLabel" aria-hidden="true">
		<div class="modal-dialog modal-dialog-centered modal-dialog-scrollable" role="document">
			<form ref="formSugerencia" v-on:submit.prevent="sendSugerencia()" class="needs-validation was-validated" novalidate>
			<div class="modal-content">
				<div class="modal-header">
					<h3 class="modal-title text-center w-100">Agregar sugerencia</h3>
				</div>
				<div class="modal-body bg-light">
					<div class="row">
						<div class="col-md-12 mb-3">
							<input type="text" v-model="sugerencia.titulo" class="form-control" placeholder="Título" maxlength="50" required>
						</div>
						<div class="col-md-12 mb-3">
							<textarea v-model="sugerencia.descripcion" class="form-control" placeholder="Descripción" maxlength="250"></textarea>
						</div>
					</div>
		    	</div>
		    	<div class="modal-footer text-right">
		    		<button class="btn btn-primary" type="submit" :disabled="loadingSugerencia || !validateForm">
		    			<BtnLoading v-if="loadingSugerencia" />
						<span v-else>
							Registrar
						</span>
		    		</button>
		    		<button class="btn btn-link" data-dismiss="modal">
		        		Cancelar
		    		</button>
		    	</div>
		  	</div>
		  	</form>
		</div>
	</div>
</template>
<script type="text/javascript">
	import { defineAsyncComponent } from 'vue'

	const BtnLoading = defineAsyncComponent({
		loader: () => import(/* webpackChunkName: "BtnLoading" */"@/components/BtnLoading.vue"),
		loadingComponent: {template:`<loading-component />`}
	})
	
	export default {
		components: {
			BtnLoading
		},
		data(){
		  return {
		  	loadingSugerencia: false,
		  	validateForm: false,

		  	sugerencia: {
		  		titulo: '',
				descripcion: ''
		  	}
		  }
		},
		mounted(){
			window.$(this.$el).modal('show')
			var vm = this
			window.$(this.$el).on('hidden.bs.modal', function () {
				vm.toIndex()
			})
		},
		methods: {
			getSugerencias(){
				this.$store.dispatch('getSugerencias',{
					idusuario: this.user.idusuario
				}).then(response => {
					return response
				}).catch(error =>{
					return error
				})
			},
			sendSugerencia(){
				this.checkValidity()
				if (!this.validateForm) {
					return
				}
				this.loadingSugerencia = true
				this.$store.dispatch('addSugerencia', {
					titulo: this.sugerencia.titulo,
					descripcion: this.sugerencia.descripcion,
					idusuario: this.user.idusuario
				}).then(response => {
					window.toastr.info("La sugerencia ha sido registrada exitosamente", "Éxito")
					this.getSugerencias()
					window.$(this.$el).modal('hide')
					return response
				}).catch(error =>{
					window.toastr.error('Error al registrar sugerencia', 'Error')
					return error
				}).then(() => {
					this.loadingSugerencia = false
				})

			},
			checkValidity(){
				if (!this.$refs.formSugerencia){
					this.validateForm = false
				}else{
					this.validateForm = this.$refs.formSugerencia.checkValidity()
				}
			},
			toIndex(){
				this.$router.push({name: 'perfil.sugerencias'})
			},
		},
		computed: {
			user(){
				return this.$store.getters.currentUser || {}
			}
		},
		watch:{
			'sugerencia':{
				handler(val){
					this.checkValidity()
					return val
				},
				deep: true
			}
		},
		beforeUnmount(){
			window.$('.modal-backdrop').remove()
		}
	}
</script>