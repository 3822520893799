<template>
	<div class="h-100">
		<div class="card text-primary mb-3">
			<div class="card-body">
				<h5 class="text-primary font-weight-bold text-left mx-lg-0 mx-3 d-flex align-items-center mb-0">
				<i class="material-icons">notifications_active</i> Anuncios
				</h5>
			</div>
		</div>
		<ul class="list-group" v-if="anuncios.length>0">
			<li class="list-group-item" v-for="(anuncio, index) in anuncios" :key="index">
				<div class="row">
					<div class="col-3" v-if="anuncio.IMG_URL">
						<img v-lazy="anuncio.IMG_URL" class="img-fluid" alt="anuncio">
					</div>
					<div :class="{'col-9':anuncio.IMG_URL, 'col-12':!anuncio.IMG_URL}">
						{{anuncio.DESCRIPCION_PUBLICACION}}
					</div>
				</div>
			</li>
		</ul>
		<div class="card" v-if="anuncios.length<=0">
			<div class="card-body">
				<div class="text-center py-5">
					<div class="bg-light p-3 m-auto d-flex align-items-center justify-content-center rounded-circle" style="height: 150px; width: 150px;">
						<img src="@/assets/image/search_dark.webp" class="img-fluid">
					</div>
					<h5>
					Aún no tienes anuncios.
					</h5>
				</div>
			</div>
		</div>
	</div>
</template>
<script type="text/javascript">
	export default {
		data(){
			return {
				loadingAnuncio: false
			}
		},
		mounted(){
			this.getAnuncios()
		},
		methods: {
			getAnuncios(){
				this.loadingAnuncio = true
				this.$store.dispatch('getAnuncios',{
					tipo: 5
				}).then(response => {
					return response
				}).catch(error =>{
					return error
				}).then(() => {
					this.loadingAnuncio = false
				})
			},
		},
		computed: {
			anuncios(){
				return this.$store.getters.anuncios || []
			},
			user(){
				return this.$store.getters.currentUser || {}
			},
		}
	}
</script>