<template>
	<div>
		<div class="bg-white p-3 rounded">
			<div class="input-group flex-nowrap mb-3" v-if="misVehiculosCurrent.length > 0">
				<div class="input-group-prepend">
					<span class="input-group-text" id="addon-wrapping">
						<img v-lazy="imgSearch" width="18" height="18">
					</span>
				</div>
				<input id="searchMisVehiculos" type="text" class="form-control bg-light border-left-0 form-control-placeholder-bold" placeholder="Buscar" aria-label="Buscar" aria-describedby="addon-wrapping" v-model="searchMisVehiculos" name="searchMisVehiculos">
			</div>

			<div class="table-responsive" v-if="misVehiculosCurrentFiltered.length>0">
				<table class="table table-sm">
					<thead>
						<tr>
							<th class="text-center" style="width: 35px;">No.</th>
							<th class="text-center" style="width:35px;">Foto</th>
							<th v-on:click="changeSorting('marca')" class="cursor-pointer text-center">
								Marca
								<span v-if="sort.column == 'marca'">
									<span class="material-icons" style="font-size: 12px;">{{sort.icon}}</span>
								</span>
							</th>
							<th v-on:click="changeSorting('modelo')" class="cursor-pointer text-center d-none d-lg-table-cell" style="min-width: 132px;">
								Modelo
								<span v-if="sort.column == 'modelo'">
									<span class="material-icons" style="font-size: 12px;">{{sort.icon}}</span>
								</span>
							</th>
							<th v-on:click="changeSorting('ano')" class="cursor-pointer text-center d-none d-lg-table-cell" style="min-width: 154px;">
								Año
								<span v-if="sort.column == 'ano'">
									<span class="material-icons" style="font-size: 12px;">{{sort.icon}}</span>
								</span>
							</th>
							<th v-on:click="changeSorting('numeroPasajeros')" class="cursor-pointer text-center d-none d-lg-table-cell" style="min-width: 120px;">
								Pasajeros
								<span v-if="sort.column == 'numeroPasajeros'">
									<span class="material-icons" style="font-size: 12px;">{{sort.icon}}</span>
								</span>
							</th>
							<th v-on:click="changeSorting('ano')" class="cursor-pointer text-center d-none d-lg-table-cell" style="min-width: 154px;">
								Fecha inspección
								<span v-if="sort.column == 'ano'">
									<span class="material-icons" style="font-size: 12px;">{{sort.icon}}</span>
								</span>
							</th>
							<th class="text-center" style="width: 50px">Acciones</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="(vehiculo, index) in misVehiculosCurrentFiltered" :key="index">
							<th>{{index+1}}</th>
							<td>
								<img style="width:35px;height:35px;object-fit: cover;" :src="$filters.storage(vehiculo.ruta, 50)" class="rounded-circle" />
							</td>
							<td>{{vehiculo.marca}}</td>
							<td class="text-center d-none d-lg-table-cell">{{vehiculo.modelo}}</td>
							<td class="text-center d-none d-lg-table-cell">{{vehiculo.ano}}</td>
							<td class="text-center d-none d-lg-table-cell">{{vehiculo.numeroPasajeros}}</td>
							<td class="text-center d-none d-lg-table-cell">
								<span v-if="vehiculo.fechaInspeccionFormat">{{vehiculo.fechaInspeccionFormat}}</span>
							</td>
							<td class="text-center">
								<router-link :to="{name:'vehiculos.show', params:{id:vehiculo.idVehiculo}}" class="btn btn-primary btn-sm p-1" title="Ver vehiculo" style="border-radius: 5px;">
									<img v-lazy="imgSearchWhite" width="18" height="18">
								</router-link>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
			<div v-else>
				<div class="text-center py-5" v-if="misVehiculosCurrent.length <= 0">
					<div class="bg-light p-3 m-auto d-flex align-items-center justify-content-center rounded-circle" style="height: 150px; width: 150px;">
						<img v-lazy="imgSearch" class="img-fluid">
					</div>
					<h5>
					Aún no tienes vehículos.
					</h5>
					<router-link :to="{name:'vehiculos.create'}" class="btn btn-primary text-white rounded-pill font-weight-bold" title="Ver vehículo" style="border-radius: 5px;">
                      Publicar vehículo
                    </router-link>
				</div>
				<div class="text-center py-5" v-else>
					<div class="bg-light p-3 m-auto d-flex align-items-center justify-content-center rounded-circle" style="height: 150px; width: 150px;">
						<img v-lazy="imgSearch" class="img-fluid">
					</div>
					<h5>
					No se encontraron resultados en la búsqueda.
					</h5>
				</div>
			</div>
		</div>
	</div>
</template>
<script type="text/javascript">
	import imgSearch from '@/assets/image/search_dark.webp'
	import imgSearchWhite from '@/assets/image/search.webp'
	export default {
		data(){
			return {
				imgSearch,
				imgSearchWhite,
				searchMisVehiculos: '',
				sort: {
					column: '',
					descending: false,
					icon: ''
				}
			}
		},
		methods: {
			changeSorting(column){
				return column
				/*var sort = this.sort;
			    if (sort.column == column) {
			        sort.descending = !sort.descending;
			        if (sort.descending) {
			          sort.icon = 'arrow_upward'
			        }else{
			          sort.icon = 'arrow_downward'
			        }
			    } else {
			        sort.column = column;
			        sort.descending = false;
			        sort.icon = 'arrow_downward'
			    }*/
			}
		},
		computed: {
			misVehiculos(){
				return this.$store.getters.misVehiculos || []
			},
			misVehiculosCurrent(){
				if (this.misVehiculos.constructor === Array) {
					return this.misVehiculos.filter(x => x.estatus == 0)
				}
				return []
			},
			misVehiculosCurrentFiltered(){
				if (this.searchMisVehiculos != '') {
					if (this.misVehiculosCurrent.constructor === Array) {
						return this.misVehiculosCurrent.filter(x => 
							(x.descripcionVehiculo || '').toString().includes(this.searchMisVehiculos) ||
							(x.idVehiculo || '').toString().includes(this.searchMisVehiculos) ||
							(x.marca || '').toString().includes(this.searchMisVehiculos) ||
							(x.modelo || '').toString().includes(this.searchMisVehiculos) ||
							(x.tipo || '').toString().includes(this.searchMisVehiculos) ||
							(x.ano || '').toString().includes(this.searchMisVehiculos) /*||
							(x.kilometraje || '').toString().includes(this.searchMisVehiculos) ||
							(x.transmision || '').toString().includes(this.searchMisVehiculos) ||
							(x.idColor || '').toString().includes(this.searchMisVehiculos) ||
							(x.idMarca || '').toString().includes(this.searchMisVehiculos) ||
							(x.idModelo || '').toString().includes(this.searchMisVehiculos) ||
							(x.idTipo || '').toString().includes(this.searchMisVehiculos)*/
						)
					}
				}
				return this.misVehiculosCurrent || []
			}
		}
	}
</script>