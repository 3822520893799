<template>
	<div class="container-fluid">
		<div class="row justify-content-center py-3 bg-credito" style="min-height: calc(100vh - 165px);">
			<div class="col-md-11">
				<div class="row align-items-stretch">
					<div class="col-lg-3 mb-3">
						<div class="card">
							<div class="card-body">
								<div class="bg-primary rounded pt-4 px-3 mx-auto overflow-hidden" style="width: 100px;">
									<img src="@/assets/image/usuarios.webp" alt="Solicitante" class="img-fluid">
								</div>
								<h4 class="text-primary font-weight-bold text-center">{{user.nomperf}}</h4>
								
								<div class="text-center mb-3 border-bottom border-top border-primary py-2" v-if="user.predio != null">
									<router-link class="btn btn-primary font-weight-bold btn-block" :to="{name:'vehiculos.create'}">
										Publicar
									</router-link>
								</div>
								<div class="text-center mb-3 border-bottom border-top border-primary py-2" v-else>
									<router-link class="btn btn-primary font-weight-bold btn-block" :to="{name:'publicacion.create'}">
										Publicar
									</router-link>
								</div>


								<div class="list-group" v-if="user.predio != null">
									<router-link :to="{name:'perfil.index'}" class="list-group-item list-group-item-action d-flex align-items-center border-0 px-0 px-md-2" exact-active-class="text-primary font-weight-bold">
										<i class="material-icons mr-1">account_circle</i>
										<div>
											Perfil
										</div>
									</router-link>
									<router-link :to="{name:'admin.predios.edit', params: {id: user.predio_slug}}" class="list-group-item list-group-item-action d-flex align-items-center border-0 px-0 px-md-2" active-class="text-primary font-weight-bold">
										<i class="material-icons mr-1">commute</i>
										<div>
											Info
										</div>
									</router-link>
									<router-link :to="{name:'admin.vehiculos.pendientes'}" class="list-group-item list-group-item-action d-flex align-items-center border-0 px-0 px-md-2" active-class="text-primary font-weight-bold">
										<i class="material-icons mr-1">directions_car</i>
										<div>
											Vehículos
										</div>
									</router-link>
								</div>
								<div class="list-group" v-else>
									<router-link :to="{name:'perfil.index'}" class="list-group-item list-group-item-action d-flex align-items-center border-0 px-0 px-md-2" exact-active-class="text-primary font-weight-bold">
										<i class="material-icons mr-1">account_circle</i>
										<div>
											Perfil
										</div>
									</router-link>
									<router-link :to="{name:'perfil.anuncios'}" class="list-group-item list-group-item-action d-flex align-items-center border-0 px-0 px-md-2" active-class="text-primary font-weight-bold">
										<i class="material-icons mr-1">contactless</i>
										<div>
											Anuncios
										</div>
									</router-link>
									<router-link :to="{name:'perfil.mispublicaciones.autorizadas'}" class="list-group-item list-group-item-action d-flex align-items-center border-0 px-0 px-md-2" active-class="text-primary font-weight-bold">
										<i class="material-icons mr-1">library_books</i>
										<div>
											Mis publicaciones
										</div>
									</router-link>
									<router-link :to="{name:'perfil.ofertasrecibidas.pendientes'}" class="list-group-item list-group-item-action d-flex align-items-center border-0 px-0 px-md-2" active-class="text-primary font-weight-bold">
										<i class="material-icons mr-1">library_books</i>
										<div>
											Ofertas recibidas
										</div>
									</router-link>
									<router-link :to="{name:'perfil.mispropuestas.autorizadas'}" class="list-group-item list-group-item-action d-flex align-items-center border-0 px-0 px-md-2" active-class="text-primary font-weight-bold">
										<i class="material-icons mr-1">library_books</i>
										<div>
											Mis propuestas
										</div>
									</router-link>
									<router-link :to="{name:'perfil.favoritos.prendas'}" class="list-group-item list-group-item-action d-flex align-items-center border-0 px-0 px-md-2" active-class="text-primary font-weight-bold">
										<i class="material-icons mr-1">star_outline</i>
										<div>
											Favoritos
										</div>
									</router-link>
									<router-link :to="{name:'perfil.misVehiculos.pendientes'}" class="list-group-item list-group-item-action d-flex align-items-center border-0 px-0 px-md-2" active-class="text-primary font-weight-bold">
										<i class="material-icons mr-1">directions_car</i>
										<div>
											Mis vehículos
										</div>
									</router-link>
									<router-link :to="{name:'perfil.misOfertasVehiculos.pendientes'}" class="list-group-item list-group-item-action d-flex align-items-center border-0 px-0 px-md-2" active-class="text-primary font-weight-bold">
										<span class="material-icons">departure_board</span>
										<div>
											Ofertas realizadas
										</div>
									</router-link>
									<router-link :to="{name:'perfil.ofertasVehiculos.pendientes'}" class="list-group-item list-group-item-action d-flex align-items-center border-0 px-0 px-md-2" active-class="text-primary font-weight-bold">
										<span class="material-icons">departure_board</span>
										<div>
											Ofertas recibidas
										</div>
									</router-link>
									<router-link :to="{name:'perfil.mis-cupones'}" class="list-group-item list-group-item-action d-flex align-items-center border-0 px-0 px-md-2" active-class="text-primary font-weight-bold">
										<i class="material-icons mr-1">receipt</i>
										<div>
											Mis cupones
										</div>
									</router-link>
									<router-link :to="{name:'perfil.estadisticas'}" class="list-group-item list-group-item-action d-flex align-items-center border-0 px-0 px-md-2" active-class="text-primary font-weight-bold">
										<i class="material-icons mr-1">assessment</i>
										<div>
											Estadísticas
										</div>
									</router-link>
									<router-link :to="{name:'perfil.cuadro-financiero'}" class="list-group-item list-group-item-action d-flex align-items-center border-0 px-0 px-md-2" active-class="text-primary font-weight-bold">
										<i class="material-icons mr-1">table_chart</i>
										<div>
											Cuadro financiero
										</div>
									</router-link>
									<router-link :to="{name:'perfil.documentos'}" class="list-group-item list-group-item-action d-flex align-items-center border-0 px-0 px-md-2" active-class="text-primary font-weight-bold">
										<i class="material-icons mr-1">folder</i>
										<div>
											Documentos
										</div>
									</router-link>
									<router-link :to="{name:'perfil.prestamos'}" class="list-group-item list-group-item-action d-flex align-items-center border-0 px-0 px-md-2" active-class="text-primary font-weight-bold">
										<i class="material-icons mr-1">list</i>
										<div>
											Préstamos
										</div>
									</router-link>
									<router-link :to="{name:'perfil.sugerencias'}" class="list-group-item list-group-item-action d-flex align-items-center border-0 px-0 px-md-2" active-class="text-primary font-weight-bold">
										<i class="material-icons mr-1">help_outline</i>
										<div>
											Sugerencias
										</div>
									</router-link>
								</div>
							</div>
						</div>
					</div>
					<div class="col-md-9 mb-3">
						<router-view />
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script type="text/javascript">
	export default {
		data(){
			return {
				loadingInversionista: false
			}
		},
		mounted(){
			this.$store.commit('footerBG', 'bg-credito')
		},
		methods:{
			getUser(){
				this.$store.dispatch('getCurrentUser').then(response => {
					return response
				}).catch(error =>{
					return error
				})
			},
		},
		computed: {
			user(){
				return this.$store.getters.currentUser || {}
			},
		},
		watch: {
			'user': {
				handler(val) {
					if(val.prf==2){
						this.$store.commit('footerBG', 'bg-inversion')
					}else{
						this.$store.commit('footerBG', 'bg-credito')
					}
				},
				deep: true
			}
		}
	}
</script>