<template>
<div>
	<div class="bg-white p-3 rounded text-center text-md-right mb-3">
		<router-link :to="{name:'beneficios.index'}" class="btn btn-inversion rounded-pill font-weight-bold px-5 text-primary">
			Ir a Club de beneficios
		</router-link>
	</div>
	<div class="bg-white p-3 rounded">
		<div class="input-group flex-nowrap mb-2" v-show="cuponesEmitidos.length>0">
			<div class="input-group-prepend">
				<span class="input-group-text" id="addon-wrapping">
					<img src="@/assets/image/search_dark.webp" width="18" height="18">
				</span>
			</div>
			<input id="searchCuponesEmitidos" type="text" class="form-control bg-light border-left-0 form-control-placeholder-bold" placeholder="Buscar" aria-label="Buscar" aria-describedby="addon-wrapping" v-model="searchCuponesEmitidos" name="searchCuponesEmitidos">
		</div>
		<div class="table-responsive cupones-table" v-if="cuponesEmitidosFiltered.length>0">
			<table class="table table-sm">
				<thead>
					<tr>
						<th class="text-center" style="width: 35px;">No.</th>
						<th class="text-center">Nombre</th>
						<th class="text-center">Fecha emisión</th>
						<th class="text-center" style="width: 50px">Acciones</th>
					</tr>
				</thead>
				<tbody>
					<tr v-for="(cupon, index) in cuponesEmitidosFiltered" :key="index" :index="index">
						<th class="center">
							<router-link :to="{name:'beneficios.cupon', params:{id:cupon.codigo}}">
								{{cupon.codigo}}
							</router-link>
						</th>
						<td class="center">
							<img v-lazy="cupon.fotoBeneficio" :alt="cupon.nombreBeneficio" width="30" height="30">
							{{cupon.nombreBeneficio}}
						</td>
						<td class="center">
							{{cupon.fechaCreacion}}
						</td>
						<td class="text-center">
							<a target="_blank" :href="$filters.url(cupon.codigoQR)">
								<img v-lazy="$filters.url(cupon.codigoQR || 'https://image.flaticon.com/icons/svg/3023/3023784.svg')" width="30" height="30" :id="cupon.codigo">
							</a>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
		<div v-else>
			<div class="text-center py-5" v-if="cuponesEmitidos.length <=0">
				<div class="bg-light p-3 m-auto d-flex align-items-center justify-content-center rounded-circle" style="height: 150px; width: 150px;">
					<img src="@/assets/image/search_dark.webp" class="img-fluid">
				</div>
				<h5>
				No tienes cupones emitidos.
				</h5>
			</div>
			<div class="text-center py-5" v-else>
				<div class="bg-light p-3 m-auto d-flex align-items-center justify-content-center rounded-circle" style="height: 150px; width: 150px;">
					<img src="@/assets/image/search_dark.webp" class="img-fluid">
				</div>
				<h5>
				No se encontraron resultados en la búsqueda.
				</h5>
			</div>
		</div>
	</div>
</div>
</template>
<script type="text/javascript">
	export default{
		data(){
			return{
				cuponesEmitidos: [],
				searchCuponesEmitidos: ''
			}
		},
		mounted(){
			this.getData()
		},
		methods: {
			getData(){
				if (!this.user.idusuario) {return}
				this.loadingData = true
				this.$store.dispatch('getMisCupones',{
					idusuario: this.user.idusuario
				}).then(response => {
					this.cuponesEmitidos = response.data || []
					return response
				}).catch(error =>{
					return error
				}).then(() => {
					this.loadingData = false
				})
			},
		},
		computed: {
			user(){
				return this.$store.getters.currentUser || {}
			},
			cuponesEmitidosFiltered(){
				if (this.searchCuponesEmitidos != '') {
					if (this.cuponesEmitidos.constructor === Array) {
						return this.cuponesEmitidos.filter(x => 
							(x.nombreBeneficio || '').toString().includes(this.searchCuponesEmitidos) || 
							(x.codigo || '').toString().includes(this.searchCuponesEmitidos) || 
							(x.fechaCreacion || '').toString().includes(this.searchCuponesEmitidos)
						)
					}
				}
				return this.cuponesEmitidos || []
			}
		},
		watch:{
			'user':{
				handler(val){
					this.getData()
					return val
				},
				deep: true
			}
		}
	}
</script>