<template>
	<Chart type="line" :data="chartdata" :options="chartoptions"></Chart>
</template>
<script type="text/javascript">
	import { defineAsyncComponent } from 'vue'

	const Chart = defineAsyncComponent({
		loader: () => import(/* webpackChunkName: "ChartsChart" */"@/components/charts/Chart.vue"),
		loadingComponent: {template:`<loading-component />`}
	})
	export default {
		props: ['datos'],
		components: {
			Chart,
		},
		computed: {
			datosDF(){
				return this.datos || []
			},
			meses(){
				if (this.datosDF.length>0) {
					var meses = ['', 'Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre']
					return this.datosDF.map(x => meses[x.mesguia] || x.mesguia)
				}
				return []
			},
			montos(){
				if (this.datosDF.length>0) {
					return this.datosDF.map(x => x.subtotal)
				}
				return []
			},
			max(){
				if (this.montos.length>0) {
					return Math.max.apply(null, this.montos) + 100
				}
				return 100
			},
			chartdata(){
				return {
					labels: this.meses,
					datasets: [{
						data: this.montos,
						label: "Dinero",
						borderColor: "#3e95cd",
						fill: true,
						tension: 0.5
					}]
				}
			},
			chartoptions(){
				return {
					responsive: true,
					scales: {
						y: {
							display: true,
							ticks: {
								maxTicksLimit: 5,
							},
							suggestedMin: 0,
							suggestedMax: this.max
						},
					},
				}
			}
		}
	}
</script>