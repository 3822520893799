<template>
	<div class="bg-white p-3 rounded">
		<div class="input-group flex-nowrap mb-2" v-if="misPublicaciones.length>0">
			<div class="input-group-prepend">
				<span class="input-group-text" id="addon-wrapping">
					<img v-lazy="imgSearch" width="18" height="18">
				</span>
			</div>
			<input id="searchMisPublicaciones" type="text" class="form-control bg-light border-left-0 form-control-placeholder-bold" placeholder="Buscar" aria-label="Buscar" aria-describedby="addon-wrapping" v-model="searchMisPublicaciones" name="searchMisPublicaciones">
		</div>
		<div class="form-row" v-if="misPublicacionesFiltered.length>0">
			<div class="col-md-3 mb-2" v-for="(publicacion, index) in misPublicacionesFiltered" :key="index">
				<router-link :to="{name:'publicacion.show', params:{id:publicacion.idprenda}}" class="card rounded cursor-pointer"> 
					<img v-lazy="$filters.storageMin(publicacion.ruta)" class="card-img-top" :alt="publicacion.nombre" style="height: 200px;" v-if="publicacion.ruta">
					<div :class="{'px-2 font-weight-bold text-white':true, 'bg-inversion':publicacion.estado==1, 'bg-gray':publicacion.estado!=1}">
						<div v-if="publicacion.estado==1">
							Invierte
						</div>
						<div v-if="publicacion.estado==5">
							Préstamo otorgado
						</div>
					</div>
					<div class="rounded-bottom bg-primary text-white px-2 pt-2">
						<div class="text-truncate">
							<span class="font-weight-bold">{{publicacion.nombre}}</span>
						</div>
						<small class="position-relative" style="top: -8px;">
							> monto: {{$filters.currency(publicacion.precio || 0)}}
						</small>
					</div>
				</router-link>
			</div>
		</div>
		<div v-else>
			<div class="text-center py-5" v-if="misPublicaciones.length <= 0">
				<div class="bg-light p-3 m-auto d-flex align-items-center justify-content-center rounded-circle" style="height: 150px; width: 150px;">
					<img v-lazy="imgSearch" class="img-fluid">
				</div>
				<h5>
				Aún no tienes publicaciones autorizadas.
				</h5>
				<router-link :to="{name:'publicacion.create'}" class="btn btn-primary text-white rounded-pill font-weight-bold">
					Registra una publicación
				</router-link>
			</div>
			<div class="text-center py-5" v-else>
				<div class="bg-light p-3 m-auto d-flex align-items-center justify-content-center rounded-circle" style="height: 150px; width: 150px;">
					<img v-lazy="imgSearch" class="img-fluid">
				</div>
				<h5>
				No se encontraron resultados en la búsqueda.
				</h5>
			</div>
		</div>
	</div>
</template>
<script type="text/javascript">
	import imgSearch from '@/assets/image/search_dark.webp'
	export default {
		data(){
			return {
				imgSearch,
				loadingData: false,
				searchMisPublicaciones: '',
				sort: {
					column: '',
					descending: false,
					icon: ''
				}
			}
		},
		methods: {
			changeSorting(column){
				return column
				/*var sort = this.sort;
			    if (sort.column == column) {
			        sort.descending = !sort.descending;
			        if (sort.descending) {
			          sort.icon = 'arrow_upward'
			        }else{
			          sort.icon = 'arrow_downward'
			        }
			    } else {
			        sort.column = column;
			        sort.descending = false;
			        sort.icon = 'arrow_downward'
			    }*/
			}
		},
		computed: {
			publicaciones(){
				return this.$store.getters.misPublicaciones || []
			},
			misPublicaciones(){
				if (this.publicaciones.constructor === Array) {
					return this.publicaciones.filter(x => x.estado == 'Denegado')
				}
				return []
			},
			misPublicacionesFiltered(){
				if (this.searchMisPublicaciones != '') {
					if (this.misPublicaciones.constructor === Array) {
						return this.misPublicaciones.filter(x => 
							(x.ruta || '').toString().includes(this.searchMisPublicaciones) ||
							(x.nombre || '').toString().includes(this.searchMisPublicaciones) ||
							(x.precio || '').toString().includes(this.searchMisPublicaciones) ||
							(x.monto || '').toString().includes(this.searchMisPublicaciones) ||
							(x.tasa || '').toString().includes(this.searchMisPublicaciones) ||
							(x.tasaPeriodo || '').toString().includes(this.searchMisPublicaciones) ||
							(x.tiempopago || '').toString().includes(this.searchMisPublicaciones) ||
							(x.tiempoPeriodo || '').toString().includes(this.searchMisPublicaciones) ||
							(x.idpropuesta || '').toString().includes(this.searchMisPublicaciones)
						)
					}
				}
				return this.misPublicaciones || []
			}
		}
	}
</script>