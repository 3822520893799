<template>
	<div>
		<div class="card text-primary mb-3">
			<div class="card-body">
				<h5 class="text-primary font-weight-bold text-left mx-lg-0 mx-3 d-flex align-items-center mb-0">
				<i class="material-icons">folder</i> Documentos
				</h5>
			</div>
		</div>
		<div>
			<div class="card">
				<div class="card-body">
					<div class="table-responsive" v-if="documentos.length>0">
						<table class="table">
							<thead>
								<tr>
									<th>Nombre</th>
									<th class="text-center">Modificado</th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="(documento, index) in documentos" :key="index">
									<th>
										<div class="d-flex align-items-start">
											<div class="mr-3">
												<span :class="[{'icon-lg':true},getExtension(documento.NOMBRE_ORIGINAL)]"></span>
											</div>
											<div>
												<a :href="$filters.crm('clientes/adjuntos/'+documento.CODIGO+'/download')" target="_blank">
													{{documento.NOMBRE_ORIGINAL}}</a>
												<br>
												<p class="mb-0 text-muted">{{documento.DESCRIPCION}}</p>
											</div>
										</div>
									</th>
									<td class="text-center">
										<TimeAgo :refresh="60" :datetime="dateParse($filters.dateTime(documento.FECHA_ACTUALIZACION))" />
									</td>
								</tr>
							</tbody>
						</table>
					</div>
					<div class="card" v-else>
						<div class="card-body">
							<div class="text-center py-5">
								<div class="bg-light p-3 m-auto d-flex align-items-center justify-content-center rounded-circle w150px h150px">
									<img v-lazy="imageSearchDark" class="img-fluid" width="20" height="20">
								</div>
								<h5>
									Aún no tienes documentos.
								</h5>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script type="text/javascript">
	import { defineAsyncComponent } from 'vue'

	const TimeAgo = defineAsyncComponent({
		loader: () => import(/* webpackChunkName: "TimeAgo" */"@/components/TimeAgo.vue"),
		loadingComponent: {template:`<loading-component />`}
	})

	export default {
		components: {
			TimeAgo
		},
		data(){
			return {
				loadingDocumento: false,
				imageSearchDark: require('@/assets/image/search_dark.webp')
			}
		},
		mounted(){
			this.getDocumentos()
		},
		methods: {
			getDocumentos(){
				if (!this.user.cliente_crm) {return}
				this.loadingDocumento = true
				this.$store.dispatch('getDocumentos',{
					cliente: this.user.cliente_crm
				}).then(response => {
					return response
				}).catch(error =>{
					return error
				}).then(() => {
					this.loadingDocumento = false
				})
			},
			getExtension(val){
				var re = /(\.jpg|\.jpeg|\.bmp|\.gif|\.png)$/i;

			    if (re.exec(val)) {
			      return 'image-icon medium-green'
			    }

			    re = /(\.pdf)$/i;
			    if (re.exec(val)) {
			      return 'pdf-icon medium-red'
			    }

			    return 'text-icon dark-blue'
			},
			dateParse(val){
				if(!val) return ''
				var dateTime = val.split(' ')
				var date = dateTime[0]
				var time = dateTime[1]

				var day = date.split('/')[0] || ''
				var month = date.split('/')[1] || ''
				var year = date.split('/')[2] || ''

				var hour = time.split(':')[0] || ''
				if(hour.length==1){
					hour = `0${hour}`
				}

				var minute = time.split(':')[1] || ''
				if(minute.length==1){
					minute = `0${minute}`
				}

				return `${year}/${month}/${day} ${hour}:${minute}`
			}
		},
		computed: {
			documentos(){
				return this.$store.getters.documentos || []
			},
			user(){
				return this.$store.getters.currentUser || {}
			},
		},
		watch: {
			'user':{
				handler(val){
					this.getDocumentos()
					return val
				},
				deep: true
			}
		}
	}
</script>