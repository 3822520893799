<template>
	<div>
		<div class="bg-white p-3 rounded">
			<div class="input-group flex-nowrap mb-3" v-if="ofertasRecibidasAprobadas.length > 0">
				<div class="input-group-prepend">
					<span class="input-group-text" id="addon-wrapping">
						<img v-lazy="imgSearch" width="18" height="18">
					</span>
				</div>
				<input id="searchOfertasRecibidasAprobadas" type="text" class="form-control bg-light border-left-0 form-control-placeholder-bold" placeholder="Buscar" aria-label="Buscar" aria-describedby="addon-wrapping" v-model="searchOfertasRecibidasAprobadas" name="searchOfertasRecibidasAprobadas">
			</div>

			<div class="table-responsive" v-if="ofertasRecibidasAprobadasFiltered.length>0">
				<table class="table table-sm">
					<thead>
						<tr>
							<th class="text-center" style="width: 35px;">No.</th>
							<th class="text-center" style="width:35px;">Foto</th>
							<th v-on:click="changeSorting('nombre')" class="cursor-pointer text-center">
								Activo
								<span v-if="sort.column == 'nombre'">
									<span class="material-icons" style="font-size: 12px;">{{sort.icon}}</span>
								</span>
							</th>
							<th v-on:click="changeSorting('precio')" class="cursor-pointer text-center d-none d-lg-table-cell" style="min-width: 132px;">
								Monto Activo
								<span v-if="sort.column == 'precio'">
									<span class="material-icons" style="font-size: 12px;">{{sort.icon}}</span>
								</span>
							</th>
							<th v-on:click="changeSorting('monto')" class="cursor-pointer text-center d-none d-lg-table-cell" style="min-width: 154px;">
								Monto Ofertado
								<span v-if="sort.column == 'monto'">
									<span class="material-icons" style="font-size: 12px;">{{sort.icon}}</span>
								</span>
							</th>
							<th v-on:click="changeSorting('tasa')" class="cursor-pointer text-center d-none d-lg-table-cell" style="min-width: 120px;">
								Tasa Interés
								<span v-if="sort.column == 'tasa'">
									<span class="material-icons" style="font-size: 12px;">{{sort.icon}}</span>
								</span>
							</th>
							<th v-on:click="changeSorting('teimpopago')" class="cursor-pointer text-center d-none d-lg-table-cell" style="min-width: 150px;">
								Tiempo de Pago
								<span v-if="sort.column == 'teimpopago'">
									<span class="material-icons" style="font-size: 12px;">{{sort.icon}}</span>
								</span>
							</th>
							<th class="text-center" style="width: 50px">Acciones</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="(propuesta, index) in ofertasRecibidasAprobadasFiltered" :key="index">
							<th>{{index+1}}</th>
							<td>
								<img style="width:35px;height:35px;object-fit: cover;" :src="$filters.storage(propuesta.ruta, 50)" class="rounded-circle" />
							</td>
							<td>{{propuesta.nombre}}</td>
							<td class="text-center d-none d-lg-table-cell">{{$filters.currency(propuesta.precio || 0)}}</td>
							<td class="text-center d-none d-lg-table-cell">{{$filters.currency(propuesta.monto || 0)}}</td>
							<td class="text-center d-none d-lg-table-cell">{{propuesta.tasa}}% {{propuesta.tasaPeriodo}}</td>
							<td class="text-center d-none d-lg-table-cell">{{propuesta.tiempopago}} {{propuesta.tiempoPeriodo}}</td>
							<td class="text-center">
								<router-link :to="{name:'propuestas.show', params:{id:propuesta.idpropuesta}}" class="btn btn-primary btn-sm p-1" title="Ver propuesta" style="border-radius: 5px;">
									<img v-lazy="imgSearchWhite" width="18" height="18">
								</router-link>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
			<div v-else>
				<div class="text-center py-5" v-if="ofertasRecibidasAprobadas.length <= 0">
					<div class="bg-light p-3 m-auto d-flex align-items-center justify-content-center rounded-circle" style="height: 150px; width: 150px;">
						<img v-lazy="imgSearch" class="img-fluid">
					</div>
					<h5>
					Aún no tienes propuestas aprobadas.
					</h5>
					<router-link :to="{name:'opciones-inversion.index'}" class="btn btn-primary text-white rounded-pill font-weight-bold" title="Ver propuesta" style="border-radius: 5px;">
                      Buscar oportunidad de inversión
                    </router-link>
				</div>
				<div class="text-center py-5" v-else>
					<div class="bg-light p-3 m-auto d-flex align-items-center justify-content-center rounded-circle" style="height: 150px; width: 150px;">
						<img v-lazy="imgSearch" class="img-fluid">
					</div>
					<h5>
					No se encontraron resultados en la búsqueda.
					</h5>
				</div>
			</div>
		</div>
	</div>
</template>
<script type="text/javascript">
	import imgSearch from '@/assets/image/search_dark.webp'
	import imgSearchWhite from '@/assets/image/search.webp'
	export default {
		data(){
			return {
				imgSearch,
				imgSearchWhite,
				loadingData: false,
				searchOfertasRecibidasAprobadas: '',
				sort: {
					column: '',
					descending: false,
					icon: ''
				}
			}
		},
		methods: {
			changeSorting(column){
				return column
				/*var sort = this.sort;
			    if (sort.column == column) {
			        sort.descending = !sort.descending;
			        if (sort.descending) {
			          sort.icon = 'arrow_upward'
			        }else{
			          sort.icon = 'arrow_downward'
			        }
			    } else {
			        sort.column = column;
			        sort.descending = false;
			        sort.icon = 'arrow_downward'
			    }*/
			}
		},
		computed: {
			ofertasRecibidas(){
				return this.$store.getters.ofertasRecibidas || []
			},
			ofertasRecibidasAprobadas(){
				if (this.ofertasRecibidas.constructor === Array) {
					return this.ofertasRecibidas.filter(x => x.estado == 3 || x.estado == 6)
				}
				return []
			},
			ofertasRecibidasAprobadasFiltered(){
				if (this.searchOfertasRecibidasAprobadas != '') {
					if (this.ofertasRecibidasAprobadas.constructor === Array) {
						return this.ofertasRecibidasAprobadas.filter(x => 
							(x.ruta || '').toString().includes(this.searchOfertasRecibidasAprobadas) ||
							(x.nombre || '').toString().includes(this.searchOfertasRecibidasAprobadas) ||
							(x.precio || '').toString().includes(this.searchOfertasRecibidasAprobadas) ||
							(x.monto || '').toString().includes(this.searchOfertasRecibidasAprobadas) ||
							(x.tasa || '').toString().includes(this.searchOfertasRecibidasAprobadas) ||
							(x.tasaPeriodo || '').toString().includes(this.searchOfertasRecibidasAprobadas) ||
							(x.tiempopago || '').toString().includes(this.searchOfertasRecibidasAprobadas) ||
							(x.tiempoPeriodo || '').toString().includes(this.searchOfertasRecibidasAprobadas) ||
							(x.idpropuesta || '').toString().includes(this.searchOfertasRecibidasAprobadas)
						)
					}
				}
				return this.ofertasRecibidasAprobadas || []
			}
		}
	}
</script>