<template>
	<div>
		<div class="card text-primary mb-3 h-100">
			<div class="card-body">
				<form ref="userForm" v-on:submit.prevent="updateProfile()" class="needs-validation was-validated row align-items-center h-100" novalidate>
					<div class="col-lg-2">
						<Upload :class="{'mb-3 text-center w-100 overflow-hidden':true, 'rounded-sm bg-light border-dashed p-3':!user.selfie, 'cursor-pointer border-dashed rounded-sm':editProfile}" :disabled="!editProfile" name="userProfile" v-on:fileChange="uploadFile($event, 'selfie')">
							<img v-lazy="$filters.storage(user.selfie, 200)" :class="{'img-fluid mx-auto':true, 'rounded':!editProfile}" alt="Foto personal tipo pasaporte / DPI" title="Agregar foto" v-if="user.selfie">
							<img src="../../assets/image/cruz.webp" :class="{'img-fluid mx-auto':true, 'rounded':!editProfile}" style="height: 35px;" alt="Foto personal tipo pasaporte / DPI" title="Agregar foto" v-else>
						</Upload>
						<div class="mb-3">
							<h5 class="rounded-pill font-weight-bold bg-light text-center py-1">{{user.alias}}</h5>
						</div>
						<div class="text-center">
							<router-link :to="{name:'logout'}" class="btn btn-danger rounded-pill font-weight-bold px-2">
								<small>Cerrar sesión</small>
							</router-link>
						</div>
					</div>
					<div class="col-lg-4">
						<div class="d-flex align-items-start flex-column h-100">
							<div class="mb-auto" v-if="editProfile">
								<input id="alias" v-model="form.alias" type="text" class="form-control" placeholder="Alias">
							</div>
							<div class="mb-auto" v-if="!editProfile">
								<label>Alias:</label>
								<br>
								<strong>{{user.alias || ''}}</strong>
								<br>
								<br>
							</div>
							<div v-if="editProfile">
								<input id="name" v-model="form.nombre" type="text" class="form-control" placeholder="Nombre">
							</div>
							<div v-if="!editProfile">
								<label>Nombre:</label>
								<br>
								<strong>{{user.nombre || ''}}</strong>
								<br>
								<br>
							</div>
							<div class="mt-auto" v-if="editProfile">
								<input id="last_name" v-model="form.apellido" type="text" class="form-control" placeholder="Apellido">
							</div>
							<div class="mt-auto" v-if="!editProfile">
								<label>Apellido:</label>
								<br>
								<strong>{{user.apellido || ''}}</strong>
								<br>
								<br>
							</div>
						</div>
					</div>
					<div class="col-lg-4">
						<div class="d-flex align-items-start flex-column h-100">
							<div>
								<label>Correo:</label>
								<br v-if="!editProfile">
								<strong>{{user.mail}}</strong>
								<br v-if="!editProfile">
								<br v-if="!editProfile">
							</div>
							<div v-if="editProfile" class="mb-auto mt-auto">
								<input id="dpi" v-model="form.dpi" type="text" class="form-control" placeholder="DPI">
							</div>
							<div v-if="!editProfile" class="mb-auto mt-auto">
								<label>DPI:</label>
								<br>
								<strong>{{user.dpi || ''}}</strong>
								<br>
								<br>
							</div>
							<div v-if="editProfile">
								<input id="telefono" v-model="form.telefono" type="text" class="form-control" placeholder="Teléfono">
							</div>
							<div v-if="!editProfile">
								<label>Teléfono:</label>
								<br>
								<strong>{{user.telefono || ''}}</strong>
								<br>
								<br>
							</div>
						</div>
					</div>
					<div class="col-lg-2">
						<Upload :class="{'overflow-hidden mb-3 text-center w-100':true, 'p-3 rounded-sm bg-light border-dashed':!user.dpifront, 'cursor-pointer border-dashed rounded-sm':editProfile}" :disabled="!editProfile" name="userDpiFront" v-on:fileChange="uploadFile($event, 'dpifront')">
							<img v-lazy="$filters.storage(user.dpifront, 100)" alt="Foto de DPI delantera" title="Agregar foto de DPI" :class="{'img-fluid mx-auto':true, 'rounded':!editProfile}" style="height: 85px;" v-if="user.dpifront"/>
							<img src="../../assets/image/cruz.webp" alt="Foto de DPI delantera" title="Agregar foto de DPI" :class="{'img-fluid mx-auto':true, 'rounded':!editProfile}" style="height: 35px;" v-else/>
						</Upload>
						<Upload :class="{'overflow-hidden mb-3 text-center w-100':true, 'p-3 rounded-sm bg-light border-dashed':!user.dpiback, 'cursor-pointer border-dashed rounded-sm':editProfile}" :disabled="!editProfile" name="userDpiBack" v-on:fileChange="uploadFile($event, 'dpiback')">
							<img v-lazy="$filters.storage(user.dpiback, 100)" alt="Agregar foto de DPI trasera" title="Agregar foto de DPI posterior" :class="{'img-fluid mx-auto':true, 'rounded':!editProfile}" style="height: 85px;" v-if="user.dpiback"/>
							<img src="../../assets/image/cruz.webp" alt="Agregar foto de DPI trasera" title="Agregar foto de DPI posterior" :class="{'img-fluid mx-auto':true, 'rounded':!editProfile}" style="height: 35px;" v-else/>
						</Upload>
						<div v-if="editProfile" class="text-center">
							<button type="submit" class="btn rounded-pill font-weight-bold btn-credito" :disabled="loadingProfile || !validateForm">
								<BtnLoading v-if="loadingProfile" />
								<span v-else>
									Guardar
								</span>
							</button>
						</div>
						<div v-else class="text-center">
							<button type="button" class="btn rounded-pill font-weight-bold btn-credito" v-on:click="setEditProfile()">
								Editar
							</button>
						</div>
					</div>
				</form>
			</div>
		</div>
		<div class="card">
			<div class="form-row mx-0">
				<div class="col-md-3 bg-primary text-white p-3 rounded-left text-center">
					<div class="d-flex h-100 align-items-center">
						<h3 class="text-white">Escoge tus intereses</h3>
					</div>
				</div>
				<div class="col-md-9 p-3">
					<ProgressLoader v-if="loadingIntereses" />
					<div class="form-row" v-else>
						<div v-for="(categoria, index) in categorias" class="col-md-6 col-lg-4 mb-3" :key="index">
							<div class="custom-control custom-checkbox">
								<input type="checkbox" class="custom-control-input" :id="categoria.id" v-model="intereses" :value="categoria.id" v-on:change="toggleIntereses($event, categoria.id)">
								<label class="custom-control-label cursor-pointer" :for="categoria.id">
									<div class="d-flex align-items-start">
										<i class="material-icons mr-1">{{materialIcons[categoria.icon]}}</i>
										{{categoria.nombre}}
									</div>
								</label>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script type="text/javascript">
	import { defineAsyncComponent } from 'vue'

	const Upload = defineAsyncComponent({
		loader: () => import(/* webpackChunkName: "Upload" */"@/components/Upload.vue"),
		loadingComponent: {template:`<loading-component />`}
	})

	const BtnLoading = defineAsyncComponent({
		loader: () => import(/* webpackChunkName: "BtnLoading" */"@/components/BtnLoading.vue"),
		loadingComponent: {template:`<loading-component />`}
	})
	
	import ProgressLoader from '@/components/ProgressLoader.vue'
	
	export default {
		components: {
			ProgressLoader,
			BtnLoading,
			Upload
		},
		data(){
			return {
				editProfile: false,
				loadingIntereses: false,
				loadingInteresesCheck: false,
				loadingProfile: false,
				validateForm: false,
				intereses: [],
				form: {
					idusuario: '',
					alias: '',
					nombre: '',
					apellido: '',
					dpi: '',
					telefono: ''
				},
				materialIcons: {
					'ion-android-pin': 'apartment',
				    'ion-ios-box': 'home_work',
				    'ion-ios-home': 'house',
				    'ion-android-bicycle': 'two_wheeler',
				    'ion-ios-briefcase': 'business',
				    'ion-ios-time': 'watch',
				    'ion-map': 'nature_people',
				    'ion-model-s': 'directions_car',
				    'inversion-colectiva': 'group'
				},
			}
		},
		mounted(){
			this.getCategorias()
			if (this.user) {
				this.getIntereses()
			}
		},
		methods: {
			setEditProfile(reset = false){
				if (reset) {
					this.editProfile = false
					this.form.idusuario = ''
					this.form.alias = ''
					this.form.nombre = ''
					this.form.apellido = ''
					this.form.dpi = ''
					this.form.telefono = ''
				}else{
					this.editProfile = true
					this.form.idusuario = this.user.idusuario || ''
					this.form.alias = this.user.alias || ''
					this.form.nombre = this.user.nombre || ''
					this.form.apellido = this.user.apellido || ''
					this.form.dpi = this.user.dpi || ''
					this.form.telefono = this.user.telefono || ''
				}
			},
			updateProfile(){
				this.checkValidity()
				if (!this.validateForm) {
					return
				}
				this.loadingProfile = true
				this.$store.dispatch('updateProfile', this.form).then(response => {
					this.getUser()
					this.setEditProfile(true)
                  	window.toastr.info("Perfil modificado exitosamente.", "Aviso")
					return response
				}).catch(error =>{
					return error
				}).then(() => {
					this.loadingProfile = false
				})
			},
			uploadFile(file, tipo){
				this.loadingFile = true
				var formData = new FormData()
				var nameFile = 'id'+this.user.idusuario+'-'+this.uniqueString()+'-'+file.name
				formData.append('files', file)
		        formData.append('nameFile', nameFile)
		        formData.append('tipo', tipo)
		        formData.append('idusuario', this.user.idusuario)

				this.$store.dispatch('updatePictureSelfDpi', formData).then(response => {
					this.getUser()
					//this.setEditProfile(true)
                  	window.toastr.info("Imagen actualizada existosamente.", "Aviso")
					return response
				}).catch(error =>{
					return error
				}).then(() => {
					this.loadingFile = false
				})
			},
			uniqueString() {
				var text     = ""
				var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789"
				for( var i=0; i < 8; i++ ) {
					text += possible.charAt(Math.floor(Math.random() * (possible.length || 0)))
				}
			    return text;
			},
			toggleIntereses(e, id){
				if (e.target.checked) {
					this.addInteres(id)
				}else{
					this.deleteInteres(id)
				}
			},
			addInteres(id){
				this.loadingInteresesCheck = true
				this.$store.dispatch('addInteres', {
					idusuario: this.user.idusuario,
					idcategoria: id
				}).then(response => {
					window.toastr.info("Interes agregado", "Aviso");
					return response
				}).catch(error =>{
					return error
				}).then(() => {
					this.loadingInteresesCheck = false
				})
			},
			deleteInteres(id){
				this.loadingInteresesCheck = true
				this.$store.dispatch('deleteInteres', {
					idusuario: this.user.idusuario,
					idcategoria: id
				}).then(response => {
					window.toastr.info("Interes eliminado", "Aviso");
					return response
				}).catch(error =>{
					return error
				}).then(() => {
					this.loadingInteresesCheck = false
				})
			},
			getIntereses(){
				this.loadingIntereses = true
				this.$store.dispatch('getIntereses', this.user).then(response => {
					if (response.data) {
						var intereses = response.data || []
						if (intereses.constructor === Array) {
							intereses = intereses.filter(x => x.selected === 'true')
							this.intereses = intereses.map(x => x.idcategoria)
						}else{
							return intereses
						}
					}
					return response
				}).catch(error =>{
					return error
				}).then(() => {
					this.loadingIntereses = false
				})
			},
			getCategorias(){
				this.$store.dispatch('getCategorias').then(response => {
					return response
				}).catch(error =>{
					return error
				})
			},
			getUser(){
				this.$store.dispatch('getCurrentUser').then(response => {
					return response
				}).catch(error =>{
					return error
				})
			},
			checkValidity(){
				if (!this.$refs.userForm){
					this.validateForm = false
				}else{
					this.validateForm = this.$refs.userForm.checkValidity()
				}
			},
		},
		computed: {
			user(){
				return this.$store.getters.currentUser || {}
			},
			categorias(){
				return this.$store.getters.categorias || []
			},
		},
		watch: {
			'user': {
				handler(val){
					this.getIntereses()
					return val
				},
				deep: true
			},
			'form':{
				handler(val){
					this.checkValidity()
					return val
				},
				deep: true
			}
		}
	}
</script>