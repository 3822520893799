<template>
	<Chart type="bar" :data="chartdata" :options="chartoptions"></Chart>
</template>
<script type="text/javascript">
	import { defineAsyncComponent } from 'vue'

	const Chart = defineAsyncComponent({
		loader: () => import(/* webpackChunkName: "ChartsChart" */"@/components/charts/Chart.vue"),
		loadingComponent: {template:`<loading-component />`}
	})
	export default {
		props: ['datos'],
		components: {
			Chart,
		},
		computed: {
			datosDF(){
				return this.datos || []
			},
			autorizadas(){
				return this.datosDF.filter(x => x.estguia == 1)
			},
			pendientes(){
				return this.datosDF.filter(x => x.estguia == 0)
			},
			denegadas(){
				return this.datosDF.filter(x => x.estguia == 2)
			},
			finalizadas(){
				return this.datosDF.filter(x => x.estguia == 3)
			},
			autorizadasArr(){
				return this.autorizadas.map(x => x.cantidad)
			},
			pendientesArr(){
				return this.pendientes.map(x => x.cantidad)
			},
			denegadasArr(){
				return this.denegadas.map(x => x.cantidad)
			},
			finalizadasArr(){
				return this.finalizadas.map(x => x.cantidad)
			},
			meses(){
				var meses = ['', 'Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre']
				return this.finalizadas.map(x => meses[x.mesguia] || x.mesguia)
			},
			montos(){
				return this.datosDF.map(x => x.cantidad)
			},
			max(){
				if (this.montos.length>0) {
					return Math.max.apply(null, this.montos) + 2
				}
				return 2
			},
			chartdata(){
				return {
					labels: this.meses,
					datasets: [
						{
							data: this.autorizadasArr,
							label: "Aprobadas",
							backgroundColor: "#1ce8b5",
							borderColor: "#3e95cd"
						},
						{
							data: this.pendientesArr,
							label: "En espera",
							backgroundColor: "#ebedf0",
							borderColor: "#3e95cd"
						},
						{
							data: this.denegadasArr,
							label: "Denegadas",
							borderColor: "#3e95cd",
							backgroundColor: "#40ceff"
						},
						{
							data: this.finalizadasArr,
							label: "Finalizadas",
							borderColor: "#3e95cd",
							backgroundColor: "#03a8f5"
						}
					]
				}
			},
			chartoptions(){
				return {
					responsive: true,
					scales: {
						y: {
							display: true,
							suggestedMin: 0,
							suggestedMax: this.max,
						}
					},
				}
			}
		}
	}
</script>