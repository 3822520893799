<template>
	<div>
		<ul class="nav nav-tabs flex-column flex-md-row nav-stacked">
			<li class="nav-item">
				<router-link :to="{name:'perfil.ofertasrecibidas.pendientes'}" class="nav-link" exact-active-class="active">
					Pendientes de aprobación
				</router-link>
			</li>
			<li class="nav-item">
				<router-link :to="{name:'perfil.ofertasrecibidas.aprobadas'}" class="nav-link" exact-active-class="active">
					Aprobadas
				</router-link>
			</li>
			<li class="nav-item">
				<router-link :to="{name:'perfil.ofertasrecibidas.rechazadas'}" class="nav-link" exact-active-class="active">
					Rechazadas
				</router-link>
			</li>
		</ul>
		<router-view />
	</div>
</template>
<script type="text/javascript">
	export default {
		mounted(){
			this.getReceivedProposal()
		},
		methods: {
			getReceivedProposal(){
				if (!this.user.idusuario) {return}
				this.$store.dispatch('getReceivedProposal',{
					idusuario: this.user.idusuario
				})
			},
		},
		computed: {
			user(){
				return this.$store.getters.currentUser || {}
			},
		},
		watch:{
			'user':{
				handler(val){
					this.getReceivedProposal()
					return val
				},
				deep: true
			}
		}
	}
</script>