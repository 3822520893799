<template>
	<div>
		<ul class="nav nav-tabs flex-column flex-md-row nav-stacked">
			<li class="nav-item">
				<router-link :to="{name:'perfil.mispropuestas.autorizadas'}" class="nav-link" exact-active-class="active">
					Autorizados
				</router-link>
			</li>
			<li class="nav-item">
				<router-link :to="{name:'perfil.mispropuestas.espera'}" class="nav-link" exact-active-class="active">
					En espera
				</router-link>
			</li>
			<li class="nav-item">
				<router-link :to="{name:'perfil.mispropuestas.denegadas'}" class="nav-link" exact-active-class="active">
					Denegados
				</router-link>
			</li>
		</ul>
		<router-view />
	</div>
</template>
<script type="text/javascript">
	export default {
		mounted(){
			this.getMyProposal()
		},
		methods: {
			getMyProposal(){
				if (!this.user.idusuario) {return}
				this.$store.dispatch('getMyProposal',{
					idusuario: this.user.idusuario
				})
			},
		},
		computed:{
			user(){
				return this.$store.getters.currentUser || {}
			}
		},
		watch:{
			'user':{
				handler(val){
					this.getMyProposal()
					return val
				},
				deep: true
			}
		}
	}
</script>