<template>
	<div>
		<div class="bg-white p-3 rounded">
			<div class="row justify-content-center">
				<div class="col-md-6">
					<div class="card bg-light">
						<div class="card-body">
							<form ref="preferenciasForm" v-on:submit.prevent="updateVehiculosPreferencias()">
								<div class="row">
									<div class="col-md-12 h-100">
										<div class="mb-1 mt-auto">
											<Currency v-model="form.presupuestoIni" step="1" class="form-control" placeholder="Presupuesto inicial"/>
										</div>
										<div class="mb-1" v-if="false">
											<Currency v-model="form.presupuestoFin" step="1" class="form-control" placeholder="Presupuesto final"/>
										</div>
										<div class="mb-1">
											<label class="mb-0">
												<small>Transmisión</small>
											</label>
											<br>
											<div class="custom-control custom-radio custom-control-inline">
												<input type="radio" id="transmisionManual" name="transmision" class="custom-control-input" v-model="form.transmision" value="1">
												<label class="custom-control-label" for="transmisionManual">Manual</label>
											</div>
											<div class="custom-control custom-radio custom-control-inline">
												<input type="radio" id="transmisionAutomatico" name="transmision" class="custom-control-input" v-model="form.transmision" value="2">
												<label class="custom-control-label" for="transmisionAutomatico">Automático</label>
											</div>
										</div>
										<div class="mb-1">
											<input type="number" name="ano" class="form-control" placeholder="Año" min="0" minlength="4" maxlength="4" v-model="form.ano" step="1">
										</div>
									</div>
									<div class="col-md-12">
										<div class="mb-1">
											<TiposVehiculos v-model="form.idTipo" />
										</div>
										<div class="mb-1">
											<Colores v-model="form.idColor" />
										</div>
										<div class="mb-1">
											<Marcas v-model="form.idMarca" />
										</div>
										<div class="mb-3">
											<Modelos v-model="form.idModelo" :marca="form.idMarca" :disabled="form.idMarca" />
										</div>
									</div>
								</div>
								<div class="text-right">
									<button type="submit" class="btn btn-success rounded font-weight-bold" :disabled="loadingUpdate">
										<BtnLoading v-if="loadingUpdate" />
										<span v-else>
											Actualizar
										</span>
									</button>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script type="text/javascript">
	import { defineAsyncComponent } from 'vue'
	
	const Currency = defineAsyncComponent({
		loader: () => import(/* webpackChunkName: "Currency" */"@/components/Currency.vue"),
		loadingComponent: {template:`<loading-component />`}
	})
	
	const BtnLoading = defineAsyncComponent({
		loader: () => import(/* webpackChunkName: "BtnLoading" */"@/components/BtnLoading.vue"),
		loadingComponent: {template:`<loading-component />`}
	})

	const Marcas = defineAsyncComponent({
		loader: () => import(/* webpackChunkName: "CatalogosMarcas" */"@/components/catalogos/Marcas.vue"),
		loadingComponent: {template:`<loading-component />`}
	})
	
	const Modelos = defineAsyncComponent({
		loader: () => import(/* webpackChunkName: "CatalogosModelos" */"@/components/catalogos/Modelos.vue"),
		loadingComponent: {template:`<loading-component />`}
	})

	const TiposVehiculos = defineAsyncComponent({
		loader: () => import(/* webpackChunkName: "CatalogosTiposVehiculos" */"@/components/catalogos/TiposVehiculos.vue"),
		loadingComponent: {template:`<loading-component />`}
	})

	const Colores = defineAsyncComponent({
		loader: () => import(/* webpackChunkName: "CatalogosColores" */"@/components/catalogos/Colores.vue"),
		loadingComponent: {template:`<loading-component />`}
	})

	export default {
		components: {
			Currency,
			BtnLoading,
			Marcas,
			Modelos,
			TiposVehiculos,
			Colores
		},
		data(){
			return {
				loadingUpdate: false,
				form: {
					idusuario: '',
					presupuestoIni: '',
					presupuestoFin: '',
					transmision: '',
					ano: '',
					idTipo: '',
					idColor: '',
					idMarca: '',
					idModelo: ''
				}
			}
		},
		mounted(){
			this.getData()
		},
		methods: {
			getData(){
				if (!this.user.idusuario) {return}
				this.$store.dispatch('getUsuariosAuto',{
					idusuario: this.user.idusuario
				}).then(res => {
					var data = res.data || {}
					this.form.idusuario = this.user.idusuario
					this.form.presupuestoIni = data.presupuestoIni || ''
					this.form.presupuestoFin = data.presupuestoFin || ''
					this.form.transmision = data.transmision || ''
					this.form.ano = data.ano || ''
					this.form.idTipo = data.idTipo || ''
					this.form.idColor = data.idColor || ''
					this.form.idMarca = data.idMarca || ''
					this.form.idModelo = data.idModelo || ''
				}).catch()
			},
			updateVehiculosPreferencias(){
				if (!this.user.idusuario) {return}
				this.loadingUpdate = true
				this.form.idusuario = this.user.idusuario
				this.$store.dispatch('updateUsuariosAuto',this.form).then(res => {
					window.toastr.info("Información actualizada", "Éxito")
					return res
				}).catch(err => {
					window.toastr.info("Error al actualizar", "Error")
					console.log(err.response)
				}).then(() => {
					this.loadingUpdate = false
				})
			}
		},
		computed: {
			user(){
				return this.$store.getters.currentUser || {}
			},
		},
		watch:{
			'user':{
				handler(val){
					this.getData()
					return val
				},
				deep: true
			}
		}
	}
</script>