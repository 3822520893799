<template>
	<div>
		<ul class="nav nav-tabs flex-column flex-md-row nav-stacked">
			<li class="nav-item">
				<router-link :to="{name:'perfil.favoritos.prendas'}" class="nav-link" exact-active-class="active">
					Prendas
				</router-link>
			</li>
			<li class="nav-item">
				<router-link :to="{name:'perfil.favoritos.usuarios'}" class="nav-link" exact-active-class="active">
					Usuarios favoritos
				</router-link>
			</li>
			<li class="nav-item">
				<router-link :to="{name:'perfil.favoritos.negocios'}" class="nav-link" exact-active-class="active">
					Usuarios con negocios
				</router-link>
			</li>
		</ul>
		<router-view />
	</div>
</template>