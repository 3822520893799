<template>
	<div class="bg-white p-3 rounded text-right overflow-auto">
		<div id="pivot"></div>
	</div>
</template>
<script type="text/javascript">
	require('@/plugins/jquery-ui.min.js')
	require('@/plugins/pivot.min.js')
	export default {
		data(){
			return {
				loadingData: false,
				cuadroFinancieroData: []
			}
		},
		mounted(){
			this.getData()
		},
		methods: {
			getData(){
				if (!this.user.idusuario) {return}
				this.loadingData = true
				this.$store.dispatch('getCuadroFinancieroData',{
					idusuario: this.user.idusuario
				}).then(response => {
					this.cuadroFinancieroData = response.data || []
					this.pivot()
					return response
				}).catch(error =>{
					return error
				}).then(() => {
					this.loadingData = false
				})
			},
			pivot(){
				var renderers = window.$.extend(window.$.pivotUtilities.renderers, window.$.pivotUtilities.gchart_renderers)
				window.$("#pivot").pivotUI(this.cuadroFinancieroData, {
					renderers: renderers,
					rows: ["USUARIO","PRENDA","MONTO_SOLICITADO","MONTO_OFRECIDO","INVERSIONISTA"],
					cols: ["ESTADO"],
					vals: ["MONTO_OFRECIDO"],
					aggregatorName: "Sum",
					rendererName: "Row Heatmap"
	            })
			}
		},
		computed: {
			user(){
				return this.$store.getters.currentUser || {}
			},
		},
		watch:{
			'user':{
				handler(val){
					this.getData()
					return val
				},
				deep: true
			}
		}
	}
</script>