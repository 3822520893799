<template>
	<Chart type="doughnut" :data="chartdata" :options="chartoptions"></Chart>
</template>
<script type="text/javascript">
	import { defineAsyncComponent } from 'vue'

	const Chart = defineAsyncComponent({
		loader: () => import(/* webpackChunkName: "ChartsChart" */"@/components/charts/Chart.vue"),
		loadingComponent: {template:`<loading-component />`}
	})
	export default {
		props: ['datos'],
		components: {
			Chart,
		},
		computed: {
			datosDF(){
				return this.datos || []
			},
			labels(){
				return ['Aprobadas', 'Pendientes', 'Rechazadas', 'Perdidas']
			},
			aprobadas(){
				return this.datosDF.filter(x => x.estado === 3 || x.estado === 6)
			},
			pendientes(){
				return this.datosDF.filter(x => x.estado === 1)
			},
			rechazadas(){
				return this.datosDF.filter(x => x.estado === 4)
			},
			perdidas(){
				return this.datosDF.filter(x => x.estado === 5)
			},
			montos(){
				return [
					(this.aprobadas.length || 0),
					(this.pendientes.length || 0),
					(this.rechazadas.length || 0),
					(this.perdidas.length || 0),
				]
			},
			chartdata(){
				return {
					labels: this.labels,
					datasets: [
						{
							data: this.montos,
							label: "Publicaciones",
							backgroundColor: ["#1ce8b5", "#ebedf0","#40ceff","#03a8f5"]
						}
					]
				}
			},
			chartoptions(){
				return {
					responsive: true,
					scales: {
						y: {
							display: false
						},
						x: {
							display: false
						},
					},
				}
			}
		}
	}
</script>