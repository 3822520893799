<template>
	<div>
		<div class="card text-primary mb-3">
			<div class="card-body">
				<h5 class="text-primary font-weight-bold text-left mx-lg-0 mx-3 d-flex align-items-center mb-0">
				<i class="material-icons">list</i> Préstamos
				</h5>
			</div>
		</div>
		<div>
			<div class="card">
				<div class="card-body">
					<div class="table-responsive" v-if="prestamos.length>0">
						<table class="table">
							<tbody>
								<tr v-for="(prestamo, index) in prestamos" :key="index">
									<td>
										<div class="form-row">
											<div class="col-md-6">
												<div class="form-row">
													<div class="col-md-12 mb-1" v-if="prestamo.tipo_prestamo">
														<label class="font-weight-bold mb-0">Tipo de préstamo:</label> {{prestamo.tipo_prestamo.DESCRIPCION_TIPO_PRESTAMO}}
													</div>
													<div class="col-md-6 mb-1">
														<label class="font-weight-bold mb-0">Fecha:</label>
														<div>
															{{$filters.date(prestamo.FECHA_INICIO)}}
														</div>
													</div>
													<div class="col-md-6 mb-1">
														<label class="font-weight-bold mb-0">Monto préstamo:</label>
														<div>
															{{$filters.currency(prestamo.MONTO_PRESTAMO)}}
														</div>
													</div>
													<div class="col-md-6 mb-1">
														<label class="font-weight-bold mb-0">Plazo:</label>
														<div>
															{{prestamo.PLAZOS}}
														</div>
													</div>
													<div class="col-md-6 mb-1">
														<label class="font-weight-bold mb-0">Cuotas pendientes:</label>
														<div>
															{{prestamo.CUOTAS_PENDIENTES}}
														</div>
													</div>
													<div class="col-md-6 mb-1">
														<label class="font-weight-bold mb-0">Mora interés:</label>
														<div>
															{{prestamo.MORA_INTERES}}
														</div>
													</div>
													<div class="col-md-6 mb-1">
														<label class="font-weight-bold mb-0">Mora Servicios:</label>
														<div>
															{{prestamo.MORA_SERVICIOS}}
														</div>
													</div>
												</div>
											</div>
											<div class="col-md-6 bg-light rounded">
												<table class="table table-sm my-2">
													<tr>
														<th class="border-0">Monto interés adeudado:</th>
														<td class="border-0" align="right">{{$filters.currency(prestamo.MONTO_INTERES_ADEUDADO)}}</td>
													</tr>
													<tr>
														<th class="border-0">Monto seguro:</th>
														<td class="border-0" align="right">{{$filters.currency(prestamo.MONTO_SEGURO)}}</td>
													</tr>
													<tr>
														<th class="border-0">Monto GPS:</th>
														<td class="border-0" align="right">{{$filters.currency(prestamo.MONTO_GPS)}}</td>
													</tr>
													<tr>
														<th class="border-0">Monto otros cargos:</th>
														<td class="border-0" align="right">{{$filters.currency(prestamo.MONTO_OTROS_CARGOS)}}</td>
													</tr>
													<tr class="border-top">
														<th class="border-0">Total a pagar:</th>
														<td class="border-0" align="right">{{$filters.currency(prestamo.TOTAL_A_PAGAR)}}</td>
													</tr>
												</table>
											</div>
										</div>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
					<div class="card" v-if="prestamos.length<=0">
						<div class="card-body">
							<div class="text-center py-5">
								<div class="bg-light p-3 m-auto d-flex align-items-center justify-content-center rounded-circle" style="height: 150px; width: 150px;">
									<img src="@/assets/image/search_dark.webp" class="img-fluid">
								</div>
								<h5>
								Aún no tienes préstamos.
								</h5>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script type="text/javascript">
	export default {
		data(){
			return {
				loadingPrestamos: false
			}
		},
		mounted(){
			this.getPrestamos()
		},
		methods: {
			getPrestamos(){
				if (!this.user.cliente_crm) {return}
				this.loadingPrestamos = true
				this.$store.dispatch('getPrestamos',{
					cliente: this.user.cliente_crm
				}).then(response => {
					return response
				}).catch(error =>{
					return error
				}).then(() => {
					this.loadingPrestamos = false
				})
			},

			dateFormat(val){
				return val
			}
		},
		computed: {
			prestamos(){
				return this.$store.getters.prestamos || []
			},
			user(){
				return this.$store.getters.currentUser || {}
			},
		},
		watch: {
			'user': {
				handler(val){
					this.getPrestamos()
					return val
				},
				deep: true
			}
		}
	}
</script>